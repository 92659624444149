import { Injectable } from '@angular/core';
import { AccountLocalStorage } from '../types/local-storage.types';
import {
  SAVED_EMAIL_LOCAL_STORAGE_KEY,
  SAVE_ACCOUNT_LOCAL_STORAGE_KEY,
  SELECTED_STORE_SESSION_LOCAL_STORAGE_KEY,
  SELECTED_STORE_LOCAL_STORAGE_KEY,
  STORES_FROM_USER_LOCAL_STORAGE_KEY,
  TOKEN_LOCAL_STORAGE_KEY
} from '@shared/constants/authentication';
import { Store } from '../types/store.types';
import { StoreSession } from '@pages/authentication/authentication.types';

@Injectable({providedIn: 'any'})
export class LocalStorageService {
  constructor() { }

  public saveEmailOnLocalStorage(email: string) {
    localStorage.setItem(SAVED_EMAIL_LOCAL_STORAGE_KEY, email);
  }

  public getEmailFromLocalStorage() {
    return localStorage.getItem(SAVED_EMAIL_LOCAL_STORAGE_KEY);
  }

  public saveAccountOnLocalStorage(account: AccountLocalStorage) {
    localStorage.setItem(SAVE_ACCOUNT_LOCAL_STORAGE_KEY, JSON.stringify(account));
  }

  public getAccountFromLocalStorage(): AccountLocalStorage {
    return JSON.parse(localStorage.getItem(SAVE_ACCOUNT_LOCAL_STORAGE_KEY) || '{}');
  }

  public saveTokenOnLocalStorage(token: string) {
    localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
  }

  public clearTokenFromLocalStorage() {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
  }

  public getTokenFromLocalStorage() {
    return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
  }

  public setStoresFromUser(stores: Store[]): void {
    localStorage.setItem(STORES_FROM_USER_LOCAL_STORAGE_KEY, JSON.stringify(stores));
  }

  public getStoresFromUser(): Store[] {
    return JSON.parse(localStorage.getItem(STORES_FROM_USER_LOCAL_STORAGE_KEY) || '[]');
  }

  public clearStoresFromUser(): void {
    localStorage.removeItem(STORES_FROM_USER_LOCAL_STORAGE_KEY);
  }

  public setUserStore(store: Store): void {
    localStorage.setItem(SELECTED_STORE_LOCAL_STORAGE_KEY, JSON.stringify(store));
  }

  public getUserStore(): Store {
    return JSON.parse(localStorage.getItem(SELECTED_STORE_LOCAL_STORAGE_KEY) || '{}');
  }

  public setOnlyUserStoreSession(store_session: StoreSession) : void {
    localStorage.setItem(SELECTED_STORE_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(store_session))
  }

  /**
   * @throws `Error`
   */
  public getOnlyUserStoreSession() : StoreSession { 
    const value = JSON.parse(localStorage.getItem(SELECTED_STORE_SESSION_LOCAL_STORAGE_KEY)) as StoreSession
    if(!value && isNaN(value.store_id) && value.store_name)
      throw new Error('The current store cannot be null')
    return value
  }

  public clearUserStore(): void {
    localStorage.removeItem(SELECTED_STORE_LOCAL_STORAGE_KEY);
  }
}