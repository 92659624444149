import { Injectable, inject } from "@angular/core";
import { OperatorFunction, tap } from "rxjs";
import { MessageService } from "primeng/api";
import { environment } from "@environments/environment";

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    private messageService: MessageService

    withMessageService(messageService: MessageService) {
        this.messageService = messageService
        return new ToastInitilizer(messageService)
    }

    create() {
        if(!this.messageService)
            throw new Error('You should call the withMessageService() method first.')
        return new ToastInitilizer(this.messageService)
    }

}

class ToastInitilizer {

    constructor(
        private readonly messageService: MessageService
    ) { }

    show(message: string, type: ToastType, muteSound: boolean = environment.muteToastSound) : void { 
        this.playPop(!muteSound)
            .finally(() =>  
                this.messageService.add({
                    key: 'toast',
                    severity: `${type}`,
                    summary: this.summary(type),
                    detail: message,
                })
            )
    }

    private playPop(play: boolean) {
        return play ? new Audio('../../assets/mp3/client_sounds_blop.mp3').play() : Promise.resolve()
    }

    private summary(type: ToastType) : string {
        switch(type) {
            case "error":
                return 'Error'
            case "success":
                return 'Éxito'
            case "warn":
                return 'Advertencia'
            case "info":
                return 'Información'
        }

    }


}

export const showToastIfRequestItsOk = () => {
    const toastService = inject(ToastService)
    return <T>(
        message: (data: T) => string, 
        type: ToastType = 'success', 
        muteSound: boolean = environment.muteToastSound) => __showToastIfRequestItsOk(message, type, muteSound, toastService)
}

export function __showToastIfRequestItsOk<T>(message: (data: T) => string, type: ToastType = 'success', muteSound: boolean, toastService: ToastService) : OperatorFunction<T, T> {
    return source => source.pipe(
        tap(_data => toastService.create().show(message(_data), type, muteSound))
    )
}

export type ToastType = 'error' | 'success' | 'warn' | 'info'