import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ToastService } from '@services/toast.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements AfterViewInit {
  title = 'protocol-front-end';

  constructor(
    private readonly messageService: MessageService,
    private readonly toastService: ToastService,
  ) { }

 
  ngAfterViewInit(): void {
    this.toastService.withMessageService(this.messageService)
  }

}
