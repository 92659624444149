import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
import { ComponentRef, Injectable } from '@angular/core';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: RootHandler } = {};
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.isDetachable(route);
  }

  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle) {
    const storeKey =  this.getStoreKey(route);
    if (handler) {
      // I need to keep track of the time the route is stored so I added storeTime.
      const rootHandler = {
        handle: handler,
        storeTime: +new Date()
      };
      this.handlers[storeKey] = rootHandler;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const storeKey =  this.getStoreKey(route);
    if (!this.isAtachable(route, storeKey)) {
      this.clearNewerHandlerOnAttach(storeKey);
      return false;
    }
    return true;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const storeKey =  this.getStoreKey(route);
    return this.handlers[storeKey]?.handle;
  }

  shouldReuseRoute( future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === current.routeConfig;
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
  }

  private getStoreKey(route: ActivatedRouteSnapshot): string {
    const dataKey = route.data['key'];
    return dataKey
  }

  // true if we mark this route shouldDetach:true
  // see it in route config
  private isDetachable(route: ActivatedRouteSnapshot) {
    if ( route?.routeConfig?.data?.['shouldDetach']) {
      return true;
    }
    return false;
  }

  private isAtachable(route: ActivatedRouteSnapshot, storeKey: string) {
    if (this.isDetachable(route) && this.handlers[storeKey]?.handle) {
      return true;
    }
    return false;
  }

  private clearNewerHandlerOnAttach(storeKey: string) {
    // console.log(storeKey)
    const handlerKeys = Object.keys(this.handlers);

    // console.log(handlerKeys)
    handlerKeys.forEach(k => {
      if (k !== storeKey) {
        const componentRef: ComponentRef<any> = (this.handlers[k].handle as any).componentRef;
        if (componentRef) {
          componentRef.destroy();
        }
        delete this.handlers[k];
      }
    });
  }
  
}

export interface RootHandler {
  handle: DetachedRouteHandle;
  storeTime: number;
}
