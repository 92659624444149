// Angular Modules
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Custom imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { URLS } from '@shared/constants/url-providers';
import { environment } from '@environments/environment';
import { AuthInterceptorService } from '@interceptors/auth.interceptor';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from '@shared/providers/custom-route-reuse.provider';
import { GlobalErrorHandler } from '@shared/providers/custom-global-error-handler.provider';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: URLS.AUTH_API_URL,
      useValue: environment.authApiUrl,
    },
    {
      provide: URLS.EMPLOYEES_API_URL,
      useValue: environment.employeesApiUrl,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
