import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-page-not-found',
  templateUrl: 'page-not-found.component.html',
  styleUrls: ['page-not-found.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule
  ]
})

export class PageNotFoundComponent implements OnInit {
  constructor(private location : Location) { }

  ngOnInit() { }

  goBack(){
    this.location.back()
  }
}