import { EnviromentType } from "@shared/constants/url-providers";

export type Environment = BaseEnvironment & DefaultEnvironmentOptions

export interface DefaultEnvironmentOptions {
    production: boolean,
    muteToastSound: boolean
    apiKey: string,
    authApiUrl: string,
    employeesApiUrl: string,
}
export interface BaseEnvironment {
    scope: EnviromentType,
    port: string | undefined
    /**
    * forces all endpoints to use the defined `scope`, so if any endpoint is configured differently, it will be ignored
    **/
    forceScope: boolean
    efact_enviroment : 'qa' | 'prod',
    url?: string;
}

export const DefaultOptions: DefaultEnvironmentOptions = {
    production: false,
    muteToastSound: false,
    apiKey: 'stagingKey',
    authApiUrl: 'https://api.lucasolutions.dev/auth',
    employeesApiUrl: 'https://api.lucasolutions.dev/employees',
}