import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoginData, LoginResponse } from './login/login.types';
import { LocalStorageService } from '@services/local-storage.service';
import { ApiUrlBuilder } from '@shared/util/api-url-builder';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Token, UserSession } from './authentication.types';
import { PermissionType } from '@shared/directives/authorizable.directive';
import { Permission } from '@shared/constants/permissions';
import { ResetEmployeePassword } from 'src/app/types/password.types';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  private session?: UserSession | null
  private token?: string | null
  private baseUrl = ApiUrlBuilder.defaultFor('AUTH_API_URL')
  private readonly jwtHelper = new JwtHelperService()

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  public checkLastPasswordUpdate() {}

  public login(loginData: LoginData) {
    return this.http.post<{message: string, payload: LoginResponse, token: string}>(`${this.baseUrl}/login`, loginData)
      .pipe(
        tap(({payload}) => this.localStorageService.setStoresFromUser(payload._store)),
        tap(({token}) => this.localStorageService.saveTokenOnLocalStorage(token))
      );
  }

  public logout() {
    return this.http.get(`${this.baseUrl}/logout`).pipe(
      tap(() => this.clearSession())
    );
  }

  public resetPassword(password: string) {
    return this.http.post(`${this.baseUrl}/reset-password`, { password });
  }

  public sendPasswordResetEmail(email: string) {
    return this.http.post(`${this.baseUrl}/send-reset-password`, { email });
  }

  public updatePassword(password: string) {
    return this.http.put(`${this.baseUrl}/reset-password`, {password}, {
      headers:  {Authorization: "Bearer " + this._token,
      "Content-Type": "application/json; charset=utf-8",}
    })
  }

  public updateEmployeePassword(payload : ResetEmployeePassword) : Observable<any>{
    
    return this.http.put(`${this.baseUrl}/reset-employee-password`, payload, {
      headers:  {Authorization: "Bearer " + this._token,
      "Content-Type": "application/json; charset=utf-8",}
    })
  }

  public verifyPasswordResetCode() {}

  checkPermission(permission: PermissionType) : boolean {
    const session = this.decodeSession()
    if(Array.isArray(permission)) {
        return permission.map(p => Permission[p].toString())
                        .filter(p => !session.role.permissions.includes(p)).length === 0
    }
    return session.role.permissions.includes(Permission[permission])
}

  public clearSession() {
    this.token = null
    this.session = null
    localStorage.clear()
  }

  public isLoggedIn() : boolean {
    try {
      this.decodeSession()
      if(!this.token)
        return false
      return !this.jwtHelper.isTokenExpired(this.token)
    }catch(err) {
      console.error('No user logged in', err)
      return false
    }
  }

  public loadSession() : void {
    this.session = null
    this.decodeSession()
  }

  public decodeSession() : UserSession | null {
    if(this.session) return this.session

    const storeSession = this.localStorageService.getOnlyUserStoreSession()
    const data = this.decodeToken()

    if(!data) return null

    const payload = data.payload
    this.session = {
      franchise_id: payload.franchise_id,
      employee_id: payload.employee_id,
      store_id: storeSession.store_id,
      store_name: storeSession.store_name,
      person_id: payload.person_id,
      person_names: payload.person_names,
      person_surnames: payload.person_surnames,
      role: {
        role_id: payload.role_id,
        name: payload.role_name,
        permissions: data.prm ?? []
      }
    }

    return this.session
  }

  public decodeToken() : Token | null {
    this.loadToken()
    return this.jwtHelper.decodeToken(this.token) as Token
  }

  public get _token() : string | null { 
    this.loadToken()
    return this.token
  }

  private loadToken() : void {
    this.token = this.localStorageService.getTokenFromLocalStorage()
  }

}