export enum Permission {
    //  Auth
    RESET_PASSWORD = 'reset_password',
  
    // Articles
    SAVE_PRODUCT = 'articles_save_product',
    SAVE_SERVICE = 'articles_save_service',
    FIND_PRODUCTS = 'articles_find_products',
    VIEW_PRODUCTS = 'articles_view_products',
    FIND_SERVICES = 'articles_find_services',
    VIEW_SERVICES = 'articles_view_services',
    ARTICLE_DETAIL = 'articles_view_article_details',
  
    // Boxes
    CASH_BOX_OPENING = 'cash_box_opening',
    VALIDATE_BOX_OPENED = 'boxes_validate_store_opened',
    OPEN_BOX = 'boxes_open_store_box',
    ADD_WITHDRAWAL = 'boxes_add_withdrawal',
    CLOSE_BOX = 'boxes_close_store_box',
    BOX_SUMMARY = 'boxes_view_summary',
    GET_BOX_INFO = 'boxes_get_store_info',
    GET_BOX_TRANSACTIONS = 'boxes_get_store_transactions',
    GET_BOX_BASICS = 'boxes_get_basic_info',
  
    //Receipts
    FIND_RECEIPTS = 'recipts_find_all',
    RECEIPTS_DETAIL = 'receipts_get_detail',
    UPDATE_RECEIPT = 'receipts_update_payment_method',
  
  
    //  Clients
    SAVE_CLIENT = 'clients_save_client',
    GET_CLIENTS = 'client_get_all_clients',
    SEARCH_CLIENT = 'clients_search_client',
    SEARCH_CLIENT_BY_DNI = 'clients_search_client_by_dni',
    CLIENT_DETAIL = 'clients_view_client_detail',
    GET_CLIENT_EXTERNAL = 'client_get_external_document',
    GET_CLIENT_INTERNAL= 'client_get_internal_document',
  
    //  Employees
    // CREATE_EMPLOYEE = 'employees_create',
    // EDIT_EMPLOYEE = 'employees_edit',
    // LIST_EMPLOYEE = 'employees_list',
    // LIST_EMPLOYEE_BY_STORE = 'employees_list_by_store',
    SAVE_EMPLOYEE = 'employees_save_employee',
    GET_ALL_EMPLOYEES = 'employees_get_all_by_store',
    SEARCH_EMPLOYEE = 'employees_search_employee',
    EMPLOYEE_DETAIL = 'employees_view_employee_detail',
    DELETE_EMPLOYEE = 'employees_delete_employee',
  
    //Franchises
    SAVE_FRANCHISE_N_STORES = 'franchises_save_franchise',
    GET_ALL_FRANCHISES = 'franchises_get_all_franchises',
    FRANCHISE_DETAIL = 'franchises_view_franchise_detail',
    DELETE_FRANCHISE = 'franchises_detele_franchise',
   
  
    //Stores
    SAVE_STORE = 'franchises_save_store',
    STORE_DETAIL = 'franchises_view_store_detail',
    DELETE_STORE = 'franchises_delete_store',
  
    // Inventory
    INVENTORY_ALL = 'inventory_all',
  
    //  Products and Services
    // CREATE_PRODUCTS_SERVICES = 'products_services_create',
    // LIST_PRODUCTS_SERVICES = 'product_services_list',
    SAVE_OFFER = 'packages_save_offer',
    GET_ALL_OFFERS = 'packages_view_offers',
    OFFER_DETAIL = 'packages_view_offer_detail',
    DELETE_OFFER = 'packages_delete_offer',
    SAVE_GIFTCARD = 'packages_save_giftcard',
    SEND_MAIL = 'packages_send_giftcard_email',
    GET_ALL_GIFTCARDS = 'packages_view_giftcards',
    SEARCH_GIFTCARD = 'packages_search_giftcard',
    GIFTCARD_DETAIL = 'packages_view_giftcard_detail',
    DELETE_GIFTCARD = 'packages_delete_gifcard',
  
    // Sales
    SALES_ALL = 'sales_all',
  
    //  Settings
    CREATE_PAYMENT_METHOD = 'setting_payment_method_create',
    CREATE_VOUCHER = 'setting_voucher_create',
    LIST_SETTINGS = 'setting_list',
  
    //Reports
    REPORT_SALES = 'access_sales_report',
    REPORT_TICKETS = 'access_avg_ticket_report',
    REPORT_CLIENTS = 'access_clients_report',
    REPORT_COMMISSIONS = 'access_commissions_report',
    REPORT_FRANCHISES = 'access_franchises_report',
  }

  export enum ClientModule {
    SAVE_CLIENT = 'clients_save_client',
    GET_CLIENTS = 'client_get_all_clients',
    SEARCH_CLIENT = 'clients_search_client',
    SEARCH_CLIENT_BY_DNI = 'clients_search_client_by_dni',
    CLIENT_DETAIL = 'clients_view_client_detail',
    GET_CLIENT_EXTERNAL = 'client_get_external_document',
    GET_CLIENT_INTERNAL= 'client_get_internal_document',
}

export enum ArticlesModule {
    SAVE_PRODUCT = 'articles_save_product',
    SAVE_SERVICE = 'articles_save_service',
    //FIND_PRODUCTS = 'articles_find_products',
    VIEW_PRODUCTS = 'articles_view_products',
    //FIND_SERVICES = 'articles_find_services',
    VIEW_SERVICES = 'articles_view_services',
    ARTICLE_DETAIL = 'articles_view_article_details',
}

export enum AuthModule {
    RESET_PASSWORD = 'reset_password',
}

export enum BoxesModule {
    CASH_BOX_OPENING = 'cash_box_opening',
    VALIDATE_BOX_OPENED = 'boxes_validate_store_opened',
    OPEN_BOX = 'boxes_open_store_box',
    ADD_WITHDRAWAL = 'boxes_add_withdrawal',
    CLOSE_BOX = 'boxes_close_store_box',
    BOX_SUMMARY = 'boxes_view_summary',
    GET_BOX_INFO = 'boxes_get_store_info',
    GET_BOX_TRANSACTIONS = 'boxes_get_store_transactions',
    GET_BOX_BASICS = 'boxes_get_basic_info',
}

export enum ReceiptsModule {
    FIND_RECEIPTS = 'recipts_find_all',
    RECEIPTS_DETAIL = 'receipts_get_detail',
    UPDATE_RECEIPT = 'receipts_update_payment_method',
}

export enum EmployeeModule {
    SAVE_EMPLOYEE = 'employees_save_employee',
    GET_ALL_EMPLOYEES = 'employees_get_all_by_store',
    SEARCH_EMPLOYEE = 'employees_search_employee',
    EMPLOYEE_DETAIL = 'employees_view_employee_detail',
    DELETE_EMPLOYEE = 'employees_delete_employee',
}

export enum FranchiseModule {
    SAVE_FRANCHISE_N_STORES = 'franchises_save_franchise',
    GET_ALL_FRANCHISES = 'franchises_get_all_franchises',
    FRANCHISE_DETAIL = 'franchises_view_franchise_detail',
    DELETE_FRANCHISE = 'franchises_detele_franchise',
    SAVE_STORE = 'franchises_save_store',
    STORE_DETAIL = 'franchises_view_store_detail',
    DELETE_STORE = 'franchises_delete_store',
}

export enum InventoryModule {
    INVENTORY_ALL = 'inventory_all',
}

export enum GiftcardModule {
    SAVE_GIFTCARD = 'packages_save_giftcard',
    SEND_MAIL = 'packages_send_giftcard_email',
    GET_ALL_GIFTCARDS = 'packages_view_giftcards',
    SEARCH_GIFTCARD = 'packages_search_giftcard',
    GIFTCARD_DETAIL = 'packages_view_giftcard_detail',
    DELETE_GIFTCARD = 'packages_delete_gifcard',
}

export enum PackagesModule {
    SAVE_OFFER = 'packages_save_offer',
    GET_ALL_OFFERS = 'packages_view_offers',
    OFFER_DETAIL = 'packages_view_offer_detail',
    DELETE_OFFER = 'packages_delete_offer',
}

export enum SalesModule {
    SALES_ALL = 'sales_all',
}

export enum SettingModule {
    CREATE_PAYMENT_METHOD = 'setting_payment_method_create',
    CREATE_VOUCHER = 'setting_voucher_create',
    LIST_SETTINGS = 'setting_list',
}

export enum ReportsModule {
    REPORT_SALES = 'access_sales_report',
    REPORT_TICKETS = 'access_avg_ticket_report',
    REPORT_CLIENTS = 'access_clients_report',
    REPORT_COMMISSIONS = 'access_commissions_report',
    REPORT_FRANCHISES = 'access_franchises_report',
}