import { environment } from "@environments/environment"
import { API_URL, EnviromentType, HTTP, HTTPS, URLS } from "@shared/constants/url-providers"

abstract class UrlConstruct {

  protected getPort(url: string) : string {
    url = this.clearProtocol(url)
    const index = url.lastIndexOf(":") + 1
    const possiblePort = url.substring(index,  url.indexOf('/', index))
    if(isNaN(Number(possiblePort)))
      throw new Error(`Port it is not provided [${url}]`)
    return possiblePort
  }

  protected getMicroservice(url: string) : string {
    const microservice = this.clearProtocol(url).match(/\/([^/]+)$/)
    if(microservice === (undefined || null) || microservice.length < 1)
      throw new Error(`Missing microservice or module for URL: ${url}`)
    return microservice[1]
  }

  protected clearProtocol(url: string) : string {
    return url.replace(url.includes(HTTP) ? HTTP : HTTPS, '')
  }
}

export class ApiUrlBuilder extends UrlConstruct {

    protected _microservice?: string = undefined
    protected _port?: string
  
    private constructor (
      private readonly enviroment?: EnviromentType
    ) {
      super()
    }
  
    public static create(enviroment?: EnviromentType) : ApiUrlBuilder {
      return new ApiUrlBuilder(enviroment)
    }

    public static defaultFor(urlKey: keyof typeof URLS) : string {
      return this.create()
                .onlyFor(urlKey)
                .build()
    }

    public static default() : Record<keyof typeof URLS, string> {
      return this.create()
                .build()
    }
  
    public microservice(microservice: string) : ApiUrlBuilder {
      this._microservice = microservice
      return this
    }
  
    public port(port: string) : ApiUrlBuilder {
      this._port = port
      return this
    }
  
    public onlyFor(urlKey: keyof typeof URLS) : SpecificUrlBuilder {
      const url = URLS[urlKey]
      if(environment.port)
        this._port = this._port ?? this.getPort(url)
      return SpecificUrlBuilder.create(this.enviroment, this._microservice ?? this.getMicroservice(url) , this._port)
    }
  
    public build() : Record<keyof typeof URLS, string> {
        const urls: Record<string, string> = {}
        Object.entries(URLS)
              .forEach(([key, url]) => {
                this._port = this._port ?? this.getPort(url)
                urls[key] = this.generateURL(this.getMicroservice(url))
              })
        return urls
    }

    private generateURL(microservice: string) : string {
      return API_URL(this.enviroment, microservice)
    }
  
  }

  export class SpecificUrlBuilder extends UrlConstruct {

    private constructor(
      private readonly env: EnviromentType, 
      private readonly microservice: string, 
      private readonly port?: string
    ) { super() }

    public static create(
      env: EnviromentType, 
      microservice: string,
      port?: string
    ) : SpecificUrlBuilder {
      return new SpecificUrlBuilder(env, microservice, port)
    }

    public build() : string {
      return API_URL(this.env, this.microservice, this.port)
    }

  }
  