// for the remember me functionality we need to save the account in the local storage
export const SAVE_ACCOUNT_LOCAL_STORAGE_KEY = 'pcl_save_account';

// if the password is restored, the login attempts will be reset
export const LOGIN_ATTEMPTS_LOCAL_STORAGE_KEY = 'pcl_login_attempts';

// we need to save the email for the resdet password functionality like the user story says
export const SAVED_EMAIL_LOCAL_STORAGE_KEY = 'pcl_saved_email';

export const PASSWORD_WAS_RESTORED_LOCAL_STORAGE_KEY = 'pcl_password_was_restored';

export const MAX_LOGIN_ATTEMPTS = 3;

export const TOKEN_LOCAL_STORAGE_KEY = 'pcl_token';
export const TOKEN_EXPIRATION_TIME = 3600;

/// 

export const STORES_FROM_USER_LOCAL_STORAGE_KEY = 'pcl_stores_from_user';

export const SELECTED_STORE_LOCAL_STORAGE_KEY = 'pcl_selected_store';

export const SELECTED_STORE_SESSION_LOCAL_STORAGE_KEY = 'pcl_selected_store_session'